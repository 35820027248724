<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">

        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #9C27B0; border-top-width: 6px;">
          <h2 class="mr-auto text-lg font-bold">Reporte Pagos de Obligaciones</h2>
          <span class="inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-dark-3 bg-gray-300 rounded">Total registros: {{ totalRecords }}</span>
        </div>

        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-12">
            <DataTable
              :value="dataPaymentsObligations"
              :paginator="true"
              :rows="20"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10,20,50,100,250,500]"
              responsiveLayout="scroll"
              currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
              showGridlines
              ref="dt"
              class="p-datatable-sm p-2 text-sm"
            >
              <template #header class="relative">
                <FiltersPaymentsObligations v-model="filters" @search="onSearch" @downloadExcel="handleDownloadXls"/>
              </template>

              <!-- Columnas -->
              <Column field="nameBank" header="Banco" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="obligationNumber" header="# Obligacion" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="creditLine" header="Línea Crédito" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="periodicity" header="Periocidad" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="paymentDate" header="Fecha Cuota" :sortable="false" style="min-width:7rem; text-align:center;"></Column>
              <Column field="pagoPendiente" header="Valor Cuota" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data.pagoPendiente, 2) }}
                </template>
              </Column>
              <Column field="" header="BBVA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO BBVA'], 2) }}
                </template>
              </Column>
              <Column field="" header="BOGOTA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO DE BOGOTA'], 2) }}
                </template>
              </Column>
              <Column field="" header="COLPATRIA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO COLPATRIA'], 2) }}
                </template>
              </Column>
              <Column field="" header="DAVIVIENDA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO DAVIVIENDA'], 2) }}
                </template>
              </Column>
              <Column field="" header="OCCIDENTE" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO DE OCCIDENTE'], 2) }}
                </template>
              </Column>
              <Column field="" header="ITAU" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCO ITAU CORPOBANCA'], 2) }}
                </template>
              </Column>
              <Column field="" header="BANCOLOMBIA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCOLOMBIA'], 2) }}
                </template>
              </Column>
              <Column field="" header="BANCOOMEVA" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(data['BANCOOMEVA'], 2) }}
                </template>
              </Column>
              <ColumnGroup type="footer">
                <Row>
                  <Column footer="Totales:" :colspan="5" footerStyle="text-align:right; font-size: 1rem" />
                  <Column :footer="totalPagoPendiente" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalBbva" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalBogota" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalColpatria" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalDavivienda" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalOccidente" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalItau" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalBancolombia" footerStyle="text-align:right; font-size: 1rem"/>
                  <Column :footer="totalBancoomeva" footerStyle="text-align:right; font-size: 1rem"/>
                </Row>
              </ColumnGroup>
            </DataTable>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import FiltersPaymentsObligations from './filters'
import listReportPaymentsObligations from '../../../services/reportPaymentsObligations'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import currency from 'currency.js'
import http from '../../../../../../../libs/http'

export default {
  name: 'reportPaymentsObligations',
  components: {
    FiltersPaymentsObligations
  },
  setup () {
    const dt = ref()
    const toast = useToast()
    const totalRecords = ref(0)
    const dataPaymentsObligations = ref([])
    const lazyParams = ref({})
    const today = new Date()
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    const formatDate = dayjs(lastDayOfMonth).format('YYYY-MM-DD')

    const filters = ref({
      fecha_corte: { value: formatDate, matchMode: 'contains' },
      bankId: { value: null, matchMode: 'contains' },
      creditLineId: { value: null, matchMode: 'contains' }
    })

    const onSearch = (event) => {
      lazyParams.value.filters = filters.value
      fetchReportPaymentsObligations(event, 'filter')
    }

    /* Obtener la lista de pagos de las obligaciones */
    const fetchReportPaymentsObligations = (event = false, typeVar) => {
      let queryParams = {
        fecha_corte: formatDate,
        current_page: 1,
        per_page: 10,
        order: 'nameBank, ASC'
      }

      if (typeVar === 'filter') {
        queryParams = {
          ...queryParams,
          fecha_corte: event.filters.fecha_corte.value ? event.filters.fecha_corte.value : null,
          creditLineId: event.filters.creditLineId.value ? event.filters.creditLineId.value : null,
          bankId: event.filters.bankId.value ? event.filters.bankId.value : null
        }
      }

      return listReportPaymentsObligations(queryParams).then(({ status, data }) => {
        if (status !== 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los pagos de las obligaciones, consulte con el administrador del sistema', life: 3000 })
        } else {
          dataPaymentsObligations.value = data.result
          totalRecords.value = dataPaymentsObligations.value.length
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cargó bien la información', life: 3000 })
        }
      })
    }

    const handleDownloadXls = (event) => {
      const queryParams = {
        fecha_corte: event.filters.fecha_corte.value ? event.filters.fecha_corte.value : null,
        creditLineId: event.filters.creditLineId.value ? event.filters.creditLineId.value : null,
        bankId: event.filters.bankId.value ? event.filters.bankId.value : null
      }
      const url = `${process.env.VUE_APP_MICROSERVICE_API_AMORTIZATIONS}/reports/payments-obligations/download`
      const res = http.getUri({ url, params: queryParams })
      window.open(res, '_blank')
    }

    const totalPagoPendiente = computed(() => {
      let total = 0
      console.log(dataPaymentsObligations)
      for (const detail of dataPaymentsObligations.value) {
        total += detail.pagoPendiente
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalBbva = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO BBVA']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalBogota = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO DE BOGOTA']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalColpatria = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO COLPATRIA']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalDavivienda = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO DAVIVIENDA']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalOccidente = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO DE OCCIDENTE']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalItau = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail['BANCO ITAU CORPOBANCA']
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalBancolombia = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail.BANCOLOMBIA
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    const totalBancoomeva = computed(() => {
      let total = 0
      for (const detail of dataPaymentsObligations.value) {
        total += detail.BANCOOMEVA
      }
      total = isNaN(total) ? 0 : total

      return `${currency(total, { separator: '.', decimal: ',', precision: 1 }).format()}`
    })

    /**/
    onMounted(() => {
      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchReportPaymentsObligations()
    })

    return {
      dt,
      filters,
      onSearch,
      dataPaymentsObligations,
      handleDownloadXls,
      totalPagoPendiente,
      totalDavivienda,
      totalItau,
      totalOccidente,
      totalBancolombia,
      totalBancoomeva,
      totalBbva,
      totalBogota,
      totalColpatria,
      totalRecords
    }
  }
}
</script>

<style lang="scss">
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol.symbol-light-primary .symbol-label {
  background-color: #E1F0FF;
  color: #3699FF;
}
.symbol.symbol-light-secondary .symbol-label {
  background-color: #EBEDF3;
  color: #E4E6EF;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #C9F7F5;
  color: #1BC5BD;
}
.symbol.symbol-light-info .symbol-label {
  background-color: #EEE5FF;
  color: #8950FC;
}
.symbol.symbol-light-warning .symbol-label {
  background-color: #FFF4DE;
  color: #FFA800;
}
.symbol.symbol-light-danger .symbol-label {
  background-color: #FFE2E5;
  color: #F64E60;
}
.symbol.symbol-light-light .symbol-label {
  background-color: #F3F6F9;
  color: #F3F6F9;
}
.symbol.symbol-light-dark .symbol-label {
  background-color: #D1D3E0;
  color: #181C32;
}
.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}
.symbol.symbol-30 > img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-30 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-50 > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.custom-style {
  .p-dialog-header {
    color: #5E8F32 !important;
  }
}

.custom-style {
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.75rem !important;
  }
  .p-fieldset-content {
    padding: 0 !important;
  }
}
</style>
